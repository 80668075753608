/*
Copyright (C) 2024 Yisi Yu

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.
 */

.app {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-size: 32px 32px;
  background-origin: content-box;
}

.app-container {
  height: 85vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
