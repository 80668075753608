/*
Copyright (C) 2024 Yisi Yu

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.
 */

.footer {
  color: #41464f;
  font-size: 14px;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #dde2e9;
}

.footer a {
  text-decoration: none;
}

.footer a:visited {
  color: #41464f;
}

.footer a:hover {
  color: #1664ff;
}

.footer span {
  padding-left: 5px;
  padding-right: 5px;
}