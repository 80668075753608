/*
Copyright (C) 2024 Yisi Yu

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.
 */

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  display: none;
}

.modal-show {
  display: block;
}

.modal > * {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  border: 1px solid #888;
}

.modal-header {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  text-align: left;
  padding: 0 5px;
  background-color: rgb(5, 170, 109);
  box-sizing: border-box;
  color: white;
}

.modal-contents {
  overflow-x: hidden;
  overflow-y: auto;
}