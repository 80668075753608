/*
Copyright (C) 2024 Yisi Yu

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.
 */

.background {
  position: fixed;
  z-index: -1;
  width: 100vw;
  height: 100vh;
}

.background-tile {
  padding: 16px;
  width: 96px;
  height: 96px;
  display: inline-block;
}

.background-row {
  text-wrap: nowrap;
}

.background-tile img {
  width: 100%;
  height: 100%;
  opacity: 0.2;
}