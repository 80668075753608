/*
Copyright (C) 2024 Yisi Yu

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.
 */

.gamelist {
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.gamelist-input {
  width: 100%;
  margin: 5px 15px 5px 5px;
}

.gamelist-items {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.gamelist-flex {
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
}

.gamelist-modal-thumbnail {
  width: 150px;
  height: 200px;
  padding-top: 10px;
  object-fit: scale-down;
}

.gamelist-modal-item {
  padding: 10px 10px;
}

.gamelist-modal-content {
  padding-top: 10px;
}
