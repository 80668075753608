/*
Copyright (C) 2024 Yisi Yu

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.
 */
.search {
  position: relative;
}

.search-input {
  padding: 10px;
  width: 90%;
  height: 50px;
  background: none;
  border: 4px solid rgb(72, 176, 53);
  border-radius: 50px;
  box-sizing: border-box;
  font-size: 12px;
  color: rgb(72, 176, 53);
  outline: none;
  transition: .5s;
}

.search:hover input {
  background: rgb(243, 255, 229);
  border-radius: 10px;
}

.search i {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(-50%, -50%);
  font-size: 26px;
  color: rgb(72, 176, 53);
  transition: .2s;
}

.search:hover i {
  opacity: 0;
  z-index: -1;
}
