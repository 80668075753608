/*
Copyright (C) 2024 Yisi Yu

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.
 */

@font-face {
  font-family: 'SuperMario256';
  src: local('SuperMario256'), url(../static/SuperMario256.ttf) format('truetype');
}

.header {
  height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  user-select: none;
}

.header-container {
  display: block;
  vertical-align: middle;
  white-space: nowrap;
}

.header-title {
  font-family: SuperMario256, fangsong;
  font-size: 100px;
  -webkit-text-stroke: 4px black;
}

.header-color-r {
  color: rgb(231, 38, 20)
}

.header-color-y {
  color: rgb(252, 208, 0)
}

.header-color-g {
  color: rgb(72, 176, 53)
}

.header-color-b {
  color: rgb(8, 158, 217)
}